
.app-error {
  margin: 20px auto;
  border: 2px solid rgb(16, 15, 15);
  /* background-color: rgb(224, 219, 209); */
  border-radius: 10px;
  font-size: 1rem;
  color: red;
  text-align: left;
  padding: 10px;
  width: 300px;
  max-width: 95vw;
}

.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10;
  background-color: #282c34;
  opacity: 0.9;
  font-size: 3rem;
}
.ribbon {
  text-align: center;
  background-color: rgb(219, 190, 83);
  /* min-height: 10px; */
  color: rgb(72, 65, 65);
  position: absolute;
  top: 50px;
  left: 0;
  padding: 3px;
  width: 100%
}
.tip {
  text-align: center;
  background-color: rgb(219, 83, 83);
  /* min-height: 10px; */
  color: rgb(72, 65, 65);
  position: absolute;
  top: 50px;
  left: 0;
  padding: 3px;
  width: 100%
}